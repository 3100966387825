<template>
    <div>
            
        <b-row>
            <b-col>
                <b-card>
                <b-row>
                    <b-col>
                        <h4>Search Submissions</h4>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <label>Submitted By</label>
                        <b-input-group>
                            <b-form-input v-model="search.submittedBy.name" :disabled="true"></b-form-input>
                            <b-input-group-append>
                                <b-button size="sm"  text="Button" variant="secondary" @click="openAddUserModal()">Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col cols="4">
                        <label>Submitted On From</label>
                        <b-form-datepicker v-model="search.submittedOnFrom"></b-form-datepicker>
                    </b-col>
                    <b-col cols="4">
                        <label>Submitted On To</label>
                        <b-form-datepicker v-model="search.submittedOnTo"></b-form-datepicker>
                    </b-col>
                </b-row>
                <hr class="mx-3">
                <b-row>
                    <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearSearch" >Cancel</b-button>
                    <b-button variant="primary" squared @click="doSearchSubmissionsForManager" class="ml-2">Search</b-button>
                    </b-col>
                </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mb-4 mt-3">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <b-table striped hover :items="tableData.dataSource" :fields="tableData.tableColumns" 
                                :busy="tableData.isLoading" @row-clicked="openCard" sort-icon-left>
                                <template #table-busy>
                                    <div class="text-center my-2">
                                        <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                    </div>
                                </template>
                                
                                <template #cell(submitDate)="row">
                                    <b-row align-v="center">
                                        <span class="mr-auto">{{row.item.submitDate | dateTimeFilter}}</span>
                                    </b-row>
                                </template>

                                <template #cell(status)="row">
                                    <b-row align-v="center">
                                        <b-badge :variant="getVariantForStatus(row.item.status)">{{row.item.status}}</b-badge>
                                    </b-row>
                                </template>

                                
                                <template #cell(actions)="row">
                                    <b-row align-v="center" align-h="end">
                                        <b-button variant="primary" size="sm" squared @click="openCard(row.item)">View</b-button>
                                    </b-row>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                    <b-row align-h="center" >
                            <b-pagination
                            v-model="tableData.currentPage"
                            :total-rows="tableData.rows"
                            :per-page="tableData.perPage"
                            ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        
        <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
            
            <b-row>
                <b-col cols="4">
                    <label>Name</label>
                    <b-form-input v-model="user.name"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Surname</label>
                    <b-form-input v-model="user.surname"></b-form-input>
                </b-col>
                <b-col cols="4">
                    <label>Email</label>
                    <b-form-input v-model="user.email"></b-form-input>
                </b-col>
            </b-row>
            <hr class="mx-3">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Clear</b-button>
                    <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col class="12">
                    <b-table striped hover 
                            :items="tableDataUsers.dataSource" 
                            :fields="tableDataUsers.tableColumns"
                            :busy="tableDataUsers.isLoading" 
                            @row-clicked="chooseUser"
                            :per-page="tableDataUsers.resultsPerPage"
                            id="userSearch"
                            :current-page="tableDataUsers.currentPage" sort-icon-left>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                            </div>
                        </template>

                        <template #cell(actions)="row">
                            <b-row align-v="center" align-h="end">
                                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                                <b-icon-chevron-right></b-icon-chevron-right>
                                </b-button>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
          
            <b-row align-h="center" >
                <b-pagination
                    v-model="tableDataUsers.currentPage"
                    :total-rows="userRows"
                    :per-page="tableDataUsers.resultsPerPage"
                    aria-controls="userSearch"
                ></b-pagination>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
    data: () => ({
        state: 'initiliazing',
        search: {
            submittedBy: {name: null, id: null},
            submittedOnFrom: null,
            submittedOnTo: null
        },
        user: {
            name: null,
            surname: null,
            email: null,
            roleID: 0
        },
        tableDataUsers: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                label: 'Name',
                key: 'name',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Surname',
                key: 'surname',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Email',
                key: 'email',
                sortable: true,
                tdClass: '',
                },
                {
                label: 'Contact Number',
                key: 'contactNumber',
                sortable: true,
                tdClass: '',
                },
                {
                label: '',
                key: 'actions',
                sortable: false,
                tdClass: ''
                }
            ]
        },
        tableData: {
            totalRecords: 10,
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Date Requested',
                    key: 'submitDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Submitted By',
                    key: 'userName',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: ''
                }
            ]
        }
    }),
    created(){
        this.setBreadcrumb([
            {
                text: 'Timesheet'
            },
            {
                text: 'Submissions Search',
                active: true
            }
        ])
        this.doSearchSubmissionsForManager()
    
    },
    methods:{
        ...mapActions(['searchSubmissionsForManager', 'searchUsersRequest']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    chooseUser(rowItem){
      //console.log(rowItem)
      this.search.submittedBy = {name: rowItem.name + ' ' + rowItem.surname, id: rowItem.userId}
      this.closeSearchUserModal()
    },
    doSearchUsers(){
      this.tableDataUsers.isLoading = true

      let request = []
      
      if (this.user.name != null) {
          request.push({'key': 'name', 'value': this.user.name})
      }
      if (this.user.surname != null) {
          request.push({'key': 'surname', 'value': this.user.surname})
      }
      if (this.user.email != null) {
          request.push({'key': 'email', 'value': this.user.email})
      }
      if (this.user.roleId != null) {
          request.push({'key': 'fkroleId', 'value': this.user.roleId})
      }
      
      this.$store.commit('setUserSearchRequest', request)
      
      this.searchUsersRequest()
      .then((request) => {
        this.tableDataUsers.dataSource = request.data
        this.tableDataUsers.isLoading = false
        this.state  = 'initialize'
      })
      .catch( () => {
        this.tableDataUsers.isLoading = false
      })
    },
    clearUsersFilter(){
      this.user = {
        name: null,
        surname: null,
        email: null
      }
    },
    openAddUserModal(){
        this.$bvModal.show('search-user-modal')
        this.user.roleId = null
        this.doSearchUsers()
    },
    closeSearchUserModal(){
        this.$bvModal.hide('search-user-modal')
    },




        clearSearch(){
            this.search = {
                submittedBy: {name: null, id: null},
                submittedOnFrom: null,
                submittedOnTo: null
            }
            this.doSearchSubmissionsForManager()
        },

        doSearchSubmissionsForManager(){
            
            this.$store.commit('setSearchSubmissionsRequest', {
                forUserId: this.search.submittedBy.id,
                fromDate: this.search.submittedOnFrom,
                toDate: this.search.submittedOnTo,
                searchAll: false
            })
            this.searchSubmissionsForManager()
            .then((res) => {
                this.tableData.dataSource = res.data
                this.tableData.isLoading = false
            })
            .catch(() => {
                this.tableData.isLoading = false
            })
        },
        openCard(rowItem){
            this.$store.commit('setSelectedTimesheet', rowItem)
            this.$router.push({path: '/timesheets/completeSubmission'})
        },
        getVariantForStatus(rowItem){
            let itemStatus = null
            switch (rowItem) {
                case 'Approved':
                    itemStatus = 'success'
                    break;
                case 'New':
                    itemStatus = 'primary'
                    break;
                case 'Denied':
                    itemStatus = 'error'
                    break;
            }

            return itemStatus
        }
    },
  computed:{
    userRows() {
        return this.tableDataUsers.dataSource.length
    }
  }
}
</script>
